import { Pie } from 'vue-chartjs'

export default {
  extends: Pie,
  props: ['data', 'options'],
  watch: {
    data() {
      this.$data._chart.destroy()
      const labels = [],
        backgroundColor = [],
        data = []
      if (this.data) {
        this.data.map((e) => {
          labels.push(e.title)
          backgroundColor.push(e.color)
          data.push(e.count)
        })
      }
      this.renderChart(
        {
          labels,
          datasets: [
            {
              backgroundColor,
              data
            }
          ]
        },
        {
          borderWidth: '10px',
          hoverBackgroundColor: 'red',
          hoverBorderWidth: '10px',
          legend: {
            display: false
          }
        }
      )
    }
  },
  mounted() {
    const labels = [],
      backgroundColor = [],
      data = []
    if (this.data) {
      this.data.map((e) => {
        labels.push(e.title)
        backgroundColor.push(e.color)
        data.push(e.count)
      })
    }
    this.renderChart(
      {
        labels,
        datasets: [
          {
            backgroundColor,
            data
          }
        ]
      },
      {
        borderWidth: '10px',
        hoverBackgroundColor: 'red',
        hoverBorderWidth: '10px',
        legend: {
          display: false
        }
      }
    )
  }
}
